.read-more-button {
  @include border-radius(3px);
  background-color: #efefef;
  text-decoration: none;
  text-align: center;
  display: block;
  margin: 0 1.5rem 0 1.5rem;
  width: 110px;
  font-size: 1rem;
  @include sans_light;
  color: #333;
  line-height: 30px;
  @include transition(all .2s ease);

  &:hover {
    background-color: #333;
    color: #fff;
  }

  &.not-looking { width: 400px; }
}


.blog-post {
  img {
    width:100% !important;
    height:auto;
    border:1px solid #6f6f6f;
  }
}


.blog-post-list img{
  width:200px;
  height:auto;
  float:right;
  border:1px solid #6f6f6f;
  margin: 0 0 10px 10px;
}

@media (max-width: 600px) {
  .blog-post-list img{
    width:100%;
    height:auto;
    float:none;
    border:1px solid #6f6f6f;
    margin: 0 0 10px 0;
  }
}

.blog-post-list, .blog-post {
  margin: 0 0 3rem;

  .post-title {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 700;
    margin: 0 0 .75rem;
    font-size: 1.5rem;
    letter-spacing: -.05rem;
    line-height: 1;
  }

  .post-meta {
    font-size: 14px;
    color: #828282;
    font-family: "Montserrat",sans-serif;

    p {
      font-family: "Montserrat",sans-serif !important;
      font-weight: 400;
      font-size: 1rem;
      color: #636363;
      letter-spacing: .01rem;
      margin-bottom: 15px;
    }

    a {
      color: #111;
      -moz-transition: .2s ease-in-out;
      -o-transition: .2s ease-in-out;
      -webkit-transition: .2s ease-in-out;
      -ms-transition: .2s ease-in-out;
      transition: .2s ease-in-out;
      letter-spacing: .01rem;
      outline: 0 !important;
      text-decoration: none;
    }
  }

  .va-middle {
    vertical-align: middle !important;
  
  }
  
  .text-xs {
    font-size: 10px;
  }
  
  .fs-12 {
    font-size: 12px !important;
  }
  
  .fs-14 {
    font-size: 14px !important;
  }
  
  .mb-5 {
    margin-bottom: 5px !important;
  }

  .mb-10 {
    margin-bottom: 10px !important;
  }
  
  .mb-20 {
    margin-bottom: 20px !important;
  }
  
  .mr-5 {
    margin-right: 5px !important;
  }
  
  .mr-15 {
    margin-right: 15px !important;
  }
  
  

}

.readmore {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-decoration: none;
  text-align: center;
  display: block;
  color: white;
  width: 100px;
  background-color: #c7c7c7;

  &:hover {
    background-color: #333;
    
  }
}


.pager {
    padding-left: 0;
    margin-bottom: 0;
}

.pager a {
    font-size: 0.875rem;
    font-weight: $medium;
}

.page-link {
    color: $title-color;
    font-size: 0.875rem;
    font-weight: $medium;
    background-color: transparent !important;
    border: none;
    border-radius: 50%;
    padding: 7px 15px;
}

.page-link:hover {
    color: $primary !important;
    background-color: transparent;
    border: none;
}

.page-link:active, .page-link:focus, .page-link:visited {
    color: $title-color;
}

.page-link:active, .page-link:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.page-item.active .page-link {
    color: $white;
    background-color: $primary !important;
    border-color: $primary !important;
}

.page-item.active .page-link:hover {
    color: $white !important;
    background-color: $primary-dark !important;
    border-color: $primary-dark !important;
}

.page-nav-left a {
    padding-left: 0 !important;
    margin-left: 0 !important;
}

.page-nav-right a {
    padding-right: 0 !important;
    margin-right: 0 !important;
}

.pill-pagination .page-item.active .page-link {
    color: $white;
    background-color: $primary !important;
    text-align: center;
    line-height: 45px;
    height: 45px;
    width: 45px;
    margin: 0 6px;
    padding: 0;
    border: none;
    border-radius: 50%;
}

.pill-pagination .page-item.active .page-link:hover {
    color: $white !important;
    background-color: $primary-dark !important;
    border-color: $primary-dark;
}

.pagination .page-link, .page-item:not(:first-child) .page-link {
    margin: 4px;
}

.page-item.disabled {
    cursor: not-allowed;
}

.page-item.disabled .page-link {
    color: $text-color;
    pointer-events: none;
    cursor: not-allowed;
    background-color: transparent;
    border-color: transparent;
    opacity: 0.5;
}

.pagination-white .page-link {
    color: $title-color;
    background-color: $white;
    border: 2px solid $secondary-dark;
}

.pagination-white .page-link:hover {
    color: $black;
    background-color: $secondary;
    border: 2px solid $secondary-dark;
}

.pagination-primary .page-link {
    color: $white;
    background-color: $primary;
    border: 2px solid $primary-dark;
}

.pagination-primary .page-link:hover {
    color: $white;
    background-color: $primary-dark;
    border: 2px solid $primary-dark;
}

.pagination-primary .page-link:active, .pagination-primary .page-link:focus, .pagination-primary .page-link:visited {
    color: $white;
}

.pagination-primary .page-item.disabled .page-link {
    color: $light-grey;
    pointer-events: none;
    cursor: not-allowed;
    background-color: $primary;
    border-color: $primary-dark;
}

.pagination-primary .page-item.active .page-link {
    color: $white;
    background-color: $primary-dark;
    border-color: $primary-dark;
}

.pager .btn:hover, .pager .btn:focus {
    color: $white;
}

.pager-left, .pager-right {
    display: inline-block;
}

.pager-left i {
    margin-right: 1px;
}

.pager-right i {
    margin-left: 1px;
}

.pager-right {
    float: right;
}
