// Resume styles
// ---------------------------------------/

// util
@import url(https://fonts.googleapis.com/css?family=Lato:700);


.section-header {
  @include section_border;
  margin-bottom: 1.5rem;

  h2 {
    @include sans;
    font-weight: 900;
    font-size: 1.75rem;
    letter-spacing: -.05rem;
    margin: 0;
  }
}

// page header styles

.page-header { padding: 1.5rem 0;

  .avatar {
    width: 120px;
    max-width: 100%;
    overflow: hidden;
    @include border-radius(100px);
    margin: 0 auto;
    display: block;
  }

  .header-name {
    @include sans;
    font-size: 3rem;
    font-weight: 900;
    text-align: center;
    letter-spacing: -.15rem;
    line-height: 1;
    margin: 1rem 0 0;
  }

  .header-contact-info {
    @include sans;
    text-align: center;
  }
  .header-contact-info p a {
    color: inherit;
  }

  .title-bar {
    @include section_border;
    margin-top: 2rem;
    padding: .4rem 0;
    @include clearfix;

    .header-title {
      @include sans;
      font-weight: 700;
      font-size: 2rem;
      line-height: 2rem;
      letter-spacing: -.05rem;
      text-align: center;
      margin: 0 0 .5rem;

      @include media_larger_than_mobile {
        float: left;
        margin: 0;
      }
    }
  }

  .executive-summary {
    @include media_larger_than_mobile { font-size: 1.125rem; }
  }
}

.icon-links {
  margin: 0;
  padding: 0;
  list-style: none;
  // position: relative;
  top: 4px;
  text-align: center;
  border-top: 2px solid #c7c7c7;
  padding-top: .4rem;

  .icolink {
    color: #D1CECC;
    @include transition(all .2s ease);
    &:hover{
      color: #333333 !important;
    }
  }

  @include media_larger_than_mobile {
    float: right;
    border-top: none;
    padding-top: 0;
  }

  .icon-link-item {
    display: inline-block;
    // margin-left: 5px;
  }

  

 

  .fa-stack {
    width: 2em;
  }

  .icon-link { display: inline-block;

    &:hover .icon path {
      fill: #333;
      @include transition(all .2s ease);
    }
  }

  .icon {
    height: 28px;
  }
}

.contact-button {
  @include border-radius(3px);
  background-color: #efefef;
  text-decoration: none;
  text-align: center;
  display: block;
  margin: 1.5rem auto;
  min-width: 220px;
  max-width: 350px;
  font-size: 1.375rem;
  @include sans_light;
  color: #333;
  line-height: 55px;
  @include transition(all .2s ease);

  &:hover {
    background-color: #333;
    color: #fff;
  }

  &.not-looking { width: 400px; }
}

.more-button {
  @include border-radius(3px);
  background-color: #efefef;
  text-decoration: none;
  text-align: center;
  display: block;
  margin: 1.5rem 20px 1.5rem auto;
  width: 75px;
  font-size: 1rem;
  @include sans_light;
  color: #333;
  line-height: 30px;
  @include transition(all .2s ease);

  &:hover {
    background-color: #333;
    color: #fff;
  }

  &.not-looking { width: 400px; }
}

.more {
  width:100%;
  overflow:hidden;  
}

.less-button {
  visibility: hidden;
  @include border-radius(3px);
  background-color: #efefef;
  text-decoration: none;
  text-align: center;
  display: block;
  margin: 1.5rem 20px 1.5rem auto;
  width: 75px;
  font-size: 1rem;
  @include sans_light;
  color: #333;
  line-height: 30px;
  @include transition(all .2s ease);

  &:hover {
    background-color: #333;
    color: #fff;
  }

  &.not-looking { width: 400px; }
}

// resume content styles

.nicelink {
  a{
    text-decoration: none;
    color:#333;
  }

  a:hover{
    text-decoration: underline;
  }
}

.content-section {
  margin: 0 0 3rem;

  .sml {
    font-size: .75rem;

    a{
      text-decoration: none;
      color:#333;
    }

    a:hover{
      text-decoration: underline;
    }
  }

  .resume-item { margin-bottom: 2rem; }

  .resume-item-title {
    @include sans_bold;
    margin: 0 0 .75rem;
    font-size: 1.5rem;
    letter-spacing: -.05rem;
    line-height: 1;
  }

  .resume-item-details {
    font-size: 1.125rem;
    margin: 0 0 .75rem;
    line-height: 1.25;

    &.award-title {
      font-size: 1rem;
    }
  }

  .resume-item-title-ext {
    margin: 0 0 .5rem;
    font-size: 1.125rem;
    letter-spacing: -.05rem;
    line-height: 1;
  }

  .resume-item-details-ext {
    margin: 0 0 .75rem;
    line-height: 1.125;

    &.award-title {
      font-size: 1rem;
    }
  }

  .resume-item-title,
  .resume-item-details,
  .resume-item-copy,
  .resume-item-list {
    a {
      color: #333;
      text-decoration: none;
      border-bottom: 1px solid;

      &:hover {
        border-bottom: none;
        color: #9c9c9c;
      }
    }
  }

  .resume-item-copy { margin: .75rem 0 0; }
}

// footer styles

.page-footer {
  border-top: 4px solid #c7c7c7;
  padding-top: 1.5rem;
  text-align: center;
  margin-bottom: 3rem;
  clear:both;

  .footer-line {
    margin: 0;
    font-size: .875rem;
    color: #999;

    > a {
      font-weight: 700;
      color: #333;
    }
  }
}

.print-only { display: none !important }

@media print {
  .no-print { display: none !important }
  .print-only { display: block !important }
  body { font-size: 12px; line-height: 1rem; }
  .page-header { padding: 1rem 0 .2rem;
    .header-name {
      @include sans_bold;
      font-size: 2rem;
      margin: 0 0 .2rem;
    }
  }
  .section-header {
    @include section_border_thin;
    margin-bottom: 1rem;
    h2 { font-size: 1.2rem; line-height: 1.5rem; }
  }
  .content-section {
    margin: 0 0 1rem;
    .resume-item { margin-bottom: 1rem; }
    .resume-item-title {
      font-size: 16px;
      line-height: .7em;
    }

    .resume-item-details {
      font-size: 12px;
      margin: 0 0 .75rem;
      line-height: .7em;
      font-style:italic;

      &.award-title {
        font-size: 11px;
      }
    }
  }
}




// carousel stuff

.client-logos {
  background-color: #ffffff;
  a{
    filter: grayscale(100%) opacity(75%);
    transition: all 500ms;
  }
  a:hover{
    filter: grayscale(0%) opacity(100%);
    
  }

}

/* Slider */
.slick-slide {
    margin: 0px 20px;
}

.slick-slide img {
    width: 100%;
}

.slick-slider
{
    position: relative;
    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    margin-bottom:30px;
}

.slick-list
{
    position: relative;
    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

// end carousel stuff

// testimonial stuff

.testimonial blockquote {
  margin: 10px 10px 0;
  background: #efefef;
  padding: 20px 60px;
  position: relative;
  border: none;
  border-radius: 8px;
  font-style: italic;
}

.testimonial blockquote:before,
.testimonial blockquote:after {
  content: "\201C";
  position: absolute;
  font-size: 80px;
  line-height: 1;
  color: #757f9a;
  font-style: normal;
}

.testimonial blockquote:before {
  top: 0;
  left: 10px;
}

.testimonial blockquote:after {
  content: "\201D";
  right: 10px;
  bottom: -0.5em;
}

.testimonial div {
  width: 0;
  height: 0;
  border-left: 0 solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #efefef;
  margin: 0 0 0 60px;
}

.testimonial p {
  margin: 8px 0 0 20px;
  text-align: left;
  color:#737373;

  a{
    text-decoration:none;
    color:#737373;
  }

  a:hover{
    color:#505050;
  }
}


// end testimonial

// second testimonial block

.client-testimonial-carousel .owl-dots button {
  height: 5px;
  background: #6e6e6e !important;
  width: 5px;
  display: inline-block;
  margin: 5px;
  transition: .5s;
  border-radius: 2px;
}

.client-testimonial-carousel button.owl-dot.active {
  background: #000 !important;
  height: 8px;
  width: 8px;
}

.client-testimonial-carousel .owl-dots {
  text-align: center;
  margin-top: 25px
}

.single-testimonial-item {
  position: relative;
  margin: 2px;
  padding: 20px;
  font-style: italic;
}

.single-testimonial-item h3 {
  font-size: 20px;
  font-style: normal;
  margin-bottom: 0;
}

.single-testimonial-item h3 span {
  display: block;
  font-size: 12px;
  font-weight: normal;
  margin-top: 5px;
}

// end second testimonial

// contact form

input:focus ~ label, textarea:focus ~ label, input:valid ~ label, textarea:valid ~ label {
  font-size: 0.75em;
  color: #999;
  top: -5px;
  -webkit-transition: all 0.225s ease;
  transition: all 0.225s ease;
}

.styled-input {
  width: 293px;
  margin: 1rem 0;
  position: relative;
  border-radius: 4px;
}

.formcell {
  width:50%;
}

.emailcell {
  float:left;
}

.phonecell {
  float:right;
}

.input_shadow:hover{
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.25);
}

@media only screen and (max-width: 768px){
  .styled-input {
      width:100%;
  }
  .formcell {
    width:100%;
  }
}

.styled-input label {
  color: #333;
  padding: 1.3rem 30px 1rem 30px;
  position: absolute;
  top: 10px;
  left: 0;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  pointer-events: none;
}

.styled-input.wide { 
  width: 650px;
  max-width: 100%;
}

input,
textarea {
  padding: 30px;
  border: 0;
  width: 100%;
  font-size: 1rem;
  background-color: #efefef;
  color: #333;
  border-radius: 4px;
}

input:focus,
textarea:focus { outline: 0; }

input:focus ~ span,
textarea:focus ~ span {
  width: 100%;
  -webkit-transition: all 0.075s ease;
  transition: all 0.075s ease;
}

textarea {
  width: 100%;
  min-height: 15em;
}

.input-container {
  width: 650px;
  max-width: 100%;
  margin: 0px auto 0px auto;
}

.submit-btn {
  padding: 7px 35px;
  border-radius: 60px;
  display: inline-block;
  background-color: #4b8cfb;
  color: white;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.06),
            0 2px 10px 0 rgba(0,0,0,0.07);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.submit-btn:hover {
  transform: translateY(1px);
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.10),
            0 1px 1px 0 rgba(0,0,0,0.09);
}

@media (max-width: 768px) {
  .submit-btn {
      width:100%;
      float: none;
      text-align:center;
  }
}

input[type=checkbox] + label {
color: #ccc;
font-style: italic;
} 

input[type=checkbox]:checked + label {
color: #f00;
font-style: normal;
}

// end contact form