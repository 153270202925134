// Base styles
// ---------------------------------------/

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  background: #f0f0f0;
}

body{
  @include serif;
  color: $text_color;
  font-size: 16px;
  line-height: 1.5;

  // display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f0f0;
}
.box {
  position: relative;
  margin: 10px auto;
  max-width: 800px;
  background: #fff;
  box-shadow: 0 0 15px rgba(0,0,0,.1);
}

.ribbon {
  --f: 10px; /* control the folded part*/
  --r: 10px; /* control the ribbon shape */
  --t: 10px; /* the top offset */
  
  position: absolute;
  inset: var(--t) calc(-1*var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  clip-path: 
  polygon(0 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 100%,
  calc(100% - var(--f)) calc(100% - var(--f)),0 calc(100% - var(--f)),
  var(--r) calc(50% - var(--f)/2));
  background: #BD1550;
  box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
  top: 20px;
  z-index: 1000;
  transition: 
    // top .25s ease-out,
    opacity .5s ease-out;
}

.hirelink{
  color:#fff;
  font: 700 18px/1 'Lato', sans-serif;
  margin:10px;
  a{
    color:#fff;
    text-decoration: none;
  }
}

.wrapper:before,
.wrapper:after { content:""; display:table; }
.wrapper:after { clear: both; }
.wrapper {
  max-width: 800px;
  padding: 0 24px;
  margin: 0 auto;
  zoom: 1;
}

// clearfix (now called group,
// via http://css-tricks.com/snippets/css/clear-fix/)
.group:before,
.group:after { content:""; display:table; }
.group:after { clear:both; }
.group { zoom:1; /* For IE 6/7 (trigger hasLayout) */ }

::-moz-selection {
    background: rgba(51,51,51,.8);
    color: #fff;
    text-shadow: none;
}
::selection {
    background: rgba(51,51,51,.8);
    color: #fff;
    text-shadow: none;
}